<template>
  <div>
    <!-- 我的页面 -->
    <div class="person">
      <div class="portrait">
        <img
          :src="
            this.userInfo.avatar ??
            'https://lanhu.oss-cn-beijing.aliyuncs.com/psy8b2t3sr3tkx4y8swf5s6pdkfjvbi6um456a9445-84b1-4014-8273-3ba1a6df31ed'
          "
          alt="icon"
          style="width: 12rem"
        />
      </div>
      <div class="account">
        <p class="no">{{ userInfo.realname }}</p>
        <p class="level">等级 ：LV1</p>
      </div>
    </div>
    <div class="center">
      <div v-for="(item, index) in contentList" :key="index" class="center-box">
        <p class="center-title">{{ item.num }}</p>
        <p class="center-num">{{ item.content }}</p>
      </div>
    </div>
    <div class="list">
      <van-cell-group>
        <div
          v-for="(item, index) in actionList"
          :key="index"
          @click="go(item.path)"
        >
          <van-cell>
            <template #title>
              <img :src="item.iconUrl" class="list-icon" />
              <span class="list-name">{{ item.name }}</span>
            </template>
            <template #right-icon>
              <van-icon name="arrow" class="list-go" />
            </template>
          </van-cell>
        </div>
      </van-cell-group>
    </div>
  </div>
</template>
<script>
import { getUserInfo } from '@/api/my/my.js'

export default {
  data() {
    return {
      contentList: [
        {
          num: 0,
          content: '阅读内容',
        },
        {
          num: 0,
          content: '内容收藏',
        },
        {
          num: 0,
          content: '课程学习',
        },
        {
          num: 0,
          content: '课程考核',
        },
      ],
      actionList: [
        {
          name: '我的阅读',
          iconUrl: require('@/assets/image/icon/my/wdyd.png'),
          path: '/read',
        },
        {
          name: '我的收藏',
          iconUrl: require('@/assets/image/icon/my/wdsc.png'),
          path: '/collect',
        },
        {
          name: '我的学习',
          iconUrl: require('@/assets/image/icon/my/wdxx.png'),
          path: '/study',
        },
        {
          name: '我的考核',
          iconUrl: require('@/assets/image/icon/my/wdkh.png'),
          path: '/test',
        },
        {
          name: '我的反馈',
          iconUrl: require('@/assets/image/icon/my/yjfk.png'),
          path: '/feedback',
        },        {
          name: '我的问卷',
          iconUrl: require('@/assets/image/icon/my/wdwj.png'),
          path: '/questionnaire',
        },
      ],
      userInfo: {}, //用户信息
      getUserInfo,
      userId: '', //用户id
      studyList: [131, 1234, 3, 137, 111], //具有查看 我的考核 页面的id
    }
  },
  created() {
    console.log(this.$store.getters.user.relTenantIds)
    this.userId = this.$store.getters.user.relTenantIds
    getUserInfo().then((res) => {
      this.userInfo = res.result
      const { readNum, collectionNum, studyNum, examNum } = this.userInfo
      this.contentList[0].num = readNum
      this.contentList[1].num = collectionNum
      this.contentList[2].num = studyNum
      this.contentList[3].num = examNum
    })
  },
  computed: {
  },
  methods: {
    go(path) {
      if (path == '/read' || path == '/study') {
        this.$toast.fail('暂未开放，敬请期待')
        return
      }
      this.$router.push(path)
    },
  },
}
</script>
<style lang="less" scoped>
.person {
  .portrait {
    display: inline-block;
    width: 12rem;
    height: 12rem;
    overflow: hidden;
    border-radius: 50%;
    margin-left: 4rem;
  }
  .account {
    display: inline-block;
    height: 12rem;
    vertical-align: top;
    margin-left: 2.5rem;
  }
  .no {
    margin-top: 5px;
    color: #000000;
    font-size: 3.375rem;
    font-weight: bold;
  }
  .level {
    margin-top: 5px;
    font-weight: 400;
    font-size: 2.625rem;
  }
}
.center {
  height: 286px;
  width: 94%;
  margin: 52px 3% 0;
  margin-top: 52px;
  border-radius: 32px;
  box-shadow: 2px 1px 2px 1px rgba(3, 3, 3, 0.05),
    -2px -2px 2px 1px rgba(3, 3, 3, 0.05);
  display: flex;
  justify-content: space-around;
  align-items: center;
  &-box {
    text-align: center;
  }
  &-title {
    font-size: 3.75rem;
    font-weight: bold;
  }
  &-num {
    font-size: 2.5rem;
    color: #666666;
    font-weight: 400;
  }
}
.list {
  margin: 42px 3% 3%;
  width: 94%;
  overflow: hidden;
  border-radius: 32px;
  box-shadow: 2px 1px 9px 1px rgba(3, 3, 3, 0.05),
    -2px -2px 9px 1px rgba(3, 3, 3, 0.05);
  &-icon {
    width: 3rem;
    margin-top: 1rem;
  }
  &-name {
    font-size: 3rem;
    color: #666666;
    font-weight: 400;
    margin: 1.3rem;
    vertical-align: top;
  }
  &-go {
    margin-top: 5px;
  }
}
</style>
